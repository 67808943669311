import { defineStore } from 'pinia'
import { toKebabCase } from '@/utils/string-util'
import { simseiApi } from '@/residency/app-props'
import userRoles from '@/residency/consts/user-roles'
import cdn from '@/consts/cdn'
import { courseEnum } from '@/residency/views/courses/course-enum'
import courseTypes from '@/residency/consts/course-types'
import noCache from '@/residency/consts/nocache-headers.js'
import { useMeStore } from '@/residency/stores/me'
import { useProctorStore } from '@/residency/stores/proctor'
import { useUserGroupStore } from '@/residency/stores/user-group'
import { useProgramStore } from '@/residency/stores/program'

function createAvailableCourse (groupCourse) {
  const course = groupCourse.course
  groupCourse.activities.forEach(activity => {
    activity.kebabName = toKebabCase(activity.name)
    delete activity._links
  })
  return {
    id: course.id,
    name: course.name,
    kebabName: toKebabCase(course.name),
    courseType: course.courseType,
    isLocked: groupCourse.isLocked,
    order: groupCourse.courseOrder,
    activities: groupCourse.activities,
    version: groupCourse.version,
    hasCognitiveAsmt: groupCourse.hasCognitiveAsmt
  }
}

const defaultSelectedCourse = () => ({
  courseType: '',
  id: '',
  name: '',
  activities: [],
  hasCognitiveAsmt: null
})

export const useCourseStore = defineStore('course', {
  persist: { storage: sessionStorage },
  state: () => ({
    selectedCourse: defaultSelectedCourse(),
    availableCourses: [],
    currentActivityId: '',
    programCourses: [],
    dashboardSelectedCourse: {}
  }),
  getters: {
    selectedCoursePath: state => toKebabCase(state.selectedCourse.name),
    isRhdPilotCourse: state => state.selectedCourse.name === courseEnum.RENAL_HILUM_DISSECTION.titleCase,
    hasRhdCourse: state => state.availableCourses.some(
      course => course.name === courseEnum.RENAL_HILUM_DISSECTION.titleCase)
  },
  actions: {
    getCourseById (id) {
      const meStore = useMeStore()
      if (meStore.isInstructor) {
        return this.programCourses.find(course => course.id === id)
      }
      return this.availableCourses.find(course => course.id === id)
    },
    getCourseIconCdnLink (courseName) {
      const envFolder = process.env.VUE_APP_ENV === 'production' ? 'prd' : 'stg'
      return `${cdn.BRANDING}/course_icons/${envFolder}/${toKebabCase(courseName)}.svg`
    },
    setDashboardSelectedCourse (course) {
      this.dashboardSelectedCourse = course
    },
    setSelectedCourse (course) {
      this.selectedCourse = course
    },
    updateAvailableCourses () {
      this.availableCourses = []
      const proctorStore = useProctorStore()
      if (proctorStore.isViewingAsProctor) {
        return this.requestGroupCourses()
      }

      const meStore = useMeStore()
      switch (meStore.role) {
        case userRoles.SIMSEI_ADMIN:
        case userRoles.SIMSEI_IMPLEMENTATION_MANAGER:
        case userRoles.FIELD_TEAM_MEMBER:
          return this.requestAllCourses()
        case userRoles.RESIDENT:
          if (!meStore.isActive) {
            return this.requestAllCourses()
          }
          return this.requestResidentGroupCourses()
        case userRoles.FACULTY:
        case userRoles.PROG_DIRECTOR:
          return this.requestGroupCourses()
        default:
          throw new Error('Invalid role type!')
      }
    },
    requestAllCourses () {
      return simseiApi.get('/course?projection=inlineActivities').then(response => {
        const availCourses = []
        response.data._embedded.course.forEach(course => {
          const courseObj = {
            id: course.id,
            name: course.name,
            kebabName: toKebabCase(course.name),
            courseType: course.courseType,
            isLocked: false,
            order: -1,
            activities: course.activities
          }
          availCourses.push(courseObj)
        })
        this.availableCourses = availCourses
        return availCourses
      }).catch(error => {
        throw error
      })
    },
    async requestResidentGroupCourses (groupId) {
      const meStore = useMeStore()
      if (!meStore.isActive) return

      const userGroupStore = useUserGroupStore()
      const selectedGroupId = groupId || userGroupStore.currentUserGroupId
      const response = await simseiApi.get(`/user-group-course/resident/${selectedGroupId}`)
      const availableCourses = response.data
        .map(courseEntity => createAvailableCourse(courseEntity))
        .filter(course => course.name !== courseEnum.FACULTY_ORIENTATION.titleCase)
      this.availableCourses = availableCourses

      return availableCourses
    },
    async requestGroupCourses () {
      const { selectedUserGroupId, selectedAllUserGroupId } = useUserGroupStore()
      if (!selectedUserGroupId) return

      const response = await simseiApi.get(`/user-group-course/faculty/${selectedAllUserGroupId}`)
      const availableCourses = response.data
        .map(courseEntity => createAvailableCourse(courseEntity))
        .filter(course => course.name !== courseEnum.RESIDENT_ORIENTATION.titleCase)
      this.availableCourses = availableCourses
      return availableCourses
    },
    async requestProgramActivities () {
      if (this.programCourses.length) return this.programCourses

      const { programId } = useProgramStore()
      const res = await simseiApi.get(`/program/faculty/all-activities/${programId}`)
      const availableCourses = res.data.map(courseActivities => createAvailableCourse(courseActivities))

      this.programCourses = availableCourses

      return availableCourses
    },
    clearCourses () {
      this.$reset()
    },
    clearSelectedCourse () {
      this.defaultSelectedCourse = defaultSelectedCourse()
    },
    setCurrentActivityId (activityId) {
      this.currentActivityId = activityId
    },
    async requestGroupCoursesForCurricularHistory (groupId) {
      const response = await simseiApi.get(`/user-group-course/resident/${groupId}`, {
        headers: {
          ...noCache
        }
      })
      const availableCourses = response.data
        .map(courseEntity => createAvailableCourse(courseEntity))
        .filter(course => course.name !== courseEnum.RESIDENT_ORIENTATION.titleCase &&
          course.courseType !== courseTypes.SOFT_SKILL.id)
      return availableCourses
    }
  }
})
